import React,{useState,useEffect} from 'react';
import { useParams } from "react-router-dom"
import firebase from 'firebase/compat/app';
import 'firebase/compat/database';
function Details() {
    const firebaseConfig = {
        apiKey: "AIzaSyBDQJdDKNdBCufcS4D13tBpAqDd-g0X4sQ",
        authDomain: "fire-d1b8d.firebaseapp.com",
        databaseURL: "https://fire-d1b8d.firebaseio.com",
        projectId: "fire-d1b8d",
        storageBucket: "fire-d1b8d.appspot.com",
        messagingSenderId: "1004958606975",
        appId: "1:1004958606975:web:e3fa4641d54b912a90014a",
        measurementId: "G-JBT17DQPN4"
      };
    firebase.initializeApp(firebaseConfig);
    let params = useParams()
    const [todoList, setTodoList] = useState();

    useEffect(() => {
        const todoRef = firebase.database().ref('dizitakip/useridler/'+params.userId);
        todoRef.on('value', (snapshot) => {
          const todos = snapshot.val();
          const todoList = [];
          for (let id in todos) {
            todoList.push({ id, ...todos[id] });
          }
          setTodoList(todoList);
        });
      }, []);

      return(
        <div className="App">
        <a href="https://play.google.com/store/apps/details?id=com.zopcuk.newseriestracker">Download for Android</a>
        <p></p>
        <a href="https://apps.apple.com/us/app/series-tracker-simple/id1614058823">Download for iOS</a>
          {
            todoList && todoList.map(blog=>{
              return(
                <div className="alperen">
                  <h3>{blog._name}</h3>
                  <p>season: {blog._season}</p>
                  <p>episode: {blog._episode}</p>
                  <p>minute: {blog._minute}</p>
                </div>
              )
            })
          }
        </div>
        );
}
export default Details;