import React, { Component } from 'react';
import firebase from 'firebase/compat/app';
import 'firebase/compat/database';
class Home extends Component {
    render() { 
        return (
            <div className='alperenhome'>
                <h1>Welcome to the Series Tracker</h1>
                <p>Share where you left off</p>
                <a href="https://play.google.com/store/apps/details?id=com.zopcuk.newseriestracker">Download for Android</a>
                <p></p>
                <a href="https://apps.apple.com/us/app/series-tracker-simple/id1614058823">Download for iOS</a>
            </div>
        );
    }
}
 
export default Home;